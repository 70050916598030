ui-nav-menu {
    display: none;
}
ui-save-bar {
    display: none;
}
.setting-widget--container {
    margin: auto;
    .setting-widget-header--sticky {
        position: sticky;
        top: 0;
        background-color: #f1f1f1;
        z-index: 99;
    }

    .setting-widget--body {
        .widget-review-display__sub-menu {
            display: none;

            position: absolute;
            top: 100%;
            left: 0;
            width: max-content;
            background-color: white;
            box-shadow: 0px 0 6px 0px rgba(0, 0, 0, 0.2);
            z-index: 100;
            border-radius: 8px;
            padding: 6px;

            &.show {
                display: block;
            }
            .widget-review-display__tab-item {
                padding: 6px;
                border-radius: 8px;
                cursor: pointer;
                &.active,
                &:hover {
                    background-color: rgba(235, 235, 235, 1);
                }
                &--prefix {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                }
            }
        }
        .mobile-setting-widget--menu {
            margin-bottom: 5px;
            cursor: pointer;
            span {
                &.Polaris-Icon {
                    margin: 0;
                }
            }
        }

        .widget-setting-collape--title {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 12px 16px;
            border-top: 1px solid #c9cccf;
            .setting-group--title {
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
            }
            span {
                margin: 0;
            }
        }

        .review-display--container {
            & > .Polaris-InlineGrid {
                height: 100%;
                overflow: hidden;
            }
            .review-display-setting--aside {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 100;
                background: #ffffff;
                transform: translate(100%, 0);
                transition: all 0.3s ease;
                &.is-active {
                    transform: translate(0, 0);
                }
                &-header {
                    height: 52px;
                    background: var(--p-color-bg-surface-brand-active);
                    border-bottom: 1px solid var(--p-color-bg-surface-brand);
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    font-weight: 650;
                    font-size: 13px;
                }
                &-body {
                    height: calc(100% - 52px);
                    overflow-y: auto;
                    padding: 16px;
                }
            }
            .review-display-setting--field {
                position: relative;
                height: 100%;
                width: 100%;
                overflow: hidden;
                border-right: 1px solid #ebecee;
                background-color: #ffffff;
                transition: height 0.3s linear;
                z-index: 99;
                &.hide {
                    height: 53px;
                    @media (min-width: 1040px) {
                        height: 100%;
                    }
                }

                @media (max-width: 1039px) {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 50vh;
                    z-index: 399;
                    border-top: 1px solid #ebecee;
                    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
                }
                &::-webkit-scrollbar {
                    display: none;
                }

                .setting-component {
                    padding: 16px;
                    border: 1px solid var(--p-color-border);
                    margin-bottom: 12px;
                    border-radius: 8px;
                    &-divider {
                        margin: 0 -16px;
                    }
                    &-btn {
                        width: 100%;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        padding: 0 8px 0 16px;
                        border: 1px solid #e3e3e3;
                        border-radius: 4px;
                        cursor: pointer;
                        text-align: left;
                        background: #ffffff;

                        &:hover {
                            background: var(--p-color-bg-surface-brand-active);
                        }
                        &-icon {
                            flex: 0 0 24px;
                            max-width: 24px;
                        }
                        &-text {
                            flex-basis: 0;
                            flex-grow: 1;
                            max-width: 100%;
                            flex: 1 1 auto;
                            word-break: break-word;
                        }
                        &-caret {
                            flex: 0 0 24x;
                            max-width: 24x;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        }
                    }
                    .component-title {
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000d30;
                    }
                    .component-item-label {
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #202223;
                    }
                    .subdued-text {
                        color: #9ca8c8;
                    }
                    .review_per_page_input {
                        font-size: var(--p-font-size-400);
                        font-weight: var(--p-font-weight-regular);
                        line-height: var(--p-font-line-height-600);
                        letter-spacing: initial;
                        position: relative;
                        z-index: var(--pc-text-field-contents);
                        width: 100%;
                        min-width: 0;
                        min-height: var(--pg-control-height);
                        padding: var(--p-space-150) var(--p-space-150)
                            var(--p-space-150) var(--p-space-300);
                        font-family: var(--p-font-family-sans);
                        caret-color: var(--p-color-text);
                        color: var(--p-color-text);
                        align-items: center;
                        border-radius: var(--p-border-radius-200);
                        border: var(--p-border-width-0165) solid
                            var(--p-color-input-border);
                        @media (min-width: 48rem) {
                            font-size: var(--p-font-size-325);
                            line-height: var(--p-font-line-height-500);
                        }
                        &:focus {
                            border-color: var(--p-color-input-border-active);
                            border-width: var(--p-border-width-025);
                            outline: var(--p-border-width-050) solid
                                var(--p-color-border-focus);
                            outline-offset: var(--p-space-025);
                        }
                    }
                }

                .drag-item--container {
                    div[role="button"] {
                        width: 100%;
                    }
                    .set-default--btn {
                        display: none;
                    }
                    &:hover {
                        .set-default--btn {
                            display: block;
                        }
                    }
                }
                .setting-widget-body--option {
                    height: 100%;
                    padding: 0px 1px;
                    .setting-layout--item {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        &:hover {
                            background-color: #f7f9fc;
                        }
                        .skeleton-field {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            #setting-widget-layout--list {
                                min-height: 50px;
                                min-width: 90px;
                                border: 1px solid #e4e5e7;
                                border-radius: 4px;
                                padding: 4.24px;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                cursor: pointer;
                                .review-skeleton {
                                    background-color: #f9f9f9;
                                    border-radius: 5px;
                                    padding: 2px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-between;
                                    border: 1px solid #dee0e4;
                                    box-sizing: border-box;
                                    .review-customer {
                                        width: fit-content;
                                        height: fit-content;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-between;
                                        .customer-avt {
                                            height: 12px;
                                            height: 12px;
                                            svg {
                                                width: 12px;
                                                height: 12px;
                                                vertical-align: top;
                                            }
                                        }
                                        .customer-info {
                                            width: 50%;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            gap: 8px;
                                            .customer-name {
                                                height: 3px;
                                                background-color: #e4e5e7;
                                            }
                                        }
                                    }
                                    .review-body {
                                        flex: 1;
                                        margin-left: 5px;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        .review-rating {
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                        }
                                        .review-content {
                                            height: 3px;
                                            background-color: #e4e5e7;
                                            margin-bottom: 3px;
                                            display: none;
                                            &.review-content-100 {
                                                width: 100%;
                                                display: block;
                                            }
                                        }
                                    }
                                    &:last-child {
                                        margin: 0;
                                    }
                                }
                                &.layout-enable {
                                    border-color: #2c6ecb;
                                    background-color: #f2f7fe;
                                    .review-skeleton {
                                        background-color: #d3e2f8;
                                        .customer-avt {
                                            svg {
                                                rect {
                                                    fill: #a2c0ed;
                                                }
                                                path {
                                                    fill: #f2f7fe;
                                                }
                                            }
                                        }
                                        .customer-info {
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            gap: 8px;
                                            .customer-name {
                                                background-color: #a2c0ed;
                                            }
                                        }
                                        .review-body {
                                            .review-rating {
                                                svg {
                                                    path {
                                                        fill: #a2c0ed;
                                                    }
                                                }
                                            }
                                            .review-content {
                                                background-color: #a2c0ed;
                                            }
                                        }
                                    }
                                }
                            }

                            #setting-widget-layout--grid {
                                min-height: 50px;
                                min-width: 90px;
                                border: 1px solid #e4e5e7;
                                border-radius: 4px;
                                padding: 4px 3px;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                cursor: pointer;
                                .review-skeleton {
                                    width: 48%;
                                    height: 40px;
                                    background-color: #f9f9f9;
                                    border-radius: 4px;
                                    padding: 3.4px;
                                    display: flex;
                                    flex-direction: column;
                                    flex-wrap: nowrap;
                                    justify-content: space-between;
                                    .review-customer {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-between;
                                        .customer-avt {
                                            height: 12px;
                                            width: 12px;
                                            margin-right: 2px;
                                            svg {
                                                width: 12px;
                                                height: 12px;
                                                vertical-align: top;
                                            }
                                        }
                                        .customer-info {
                                            flex: 1;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            gap: 8px;
                                            .customer-name {
                                                height: 3px;
                                                background-color: #e4e5e7;
                                            }
                                        }
                                    }
                                    .review-body {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        .review-rating {
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            margin-bottom: 1.7px;
                                        }
                                        .review-content {
                                            height: 3px;
                                            background-color: #e4e5e7;
                                            margin-bottom: 1.7px;
                                        }
                                    }
                                }
                                &.layout-enable {
                                    border-color: #2c6ecb;
                                    background-color: #f2f7fe;
                                    .review-skeleton {
                                        background-color: #d3e2f8;
                                        .customer-avt {
                                            svg {
                                                rect {
                                                    fill: #a2c0ed;
                                                }
                                                path {
                                                    fill: #f2f7fe;
                                                }
                                            }
                                        }
                                        .customer-info {
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            gap: 8px;
                                            .customer-name {
                                                background-color: #a2c0ed;
                                            }
                                        }
                                        .review-body {
                                            .review-rating {
                                                svg {
                                                    path {
                                                        fill: #a2c0ed;
                                                    }
                                                }
                                            }
                                            .review-content {
                                                background-color: #a2c0ed;
                                            }
                                        }
                                    }
                                }
                            }

                            #setting-widget-summary--left {
                                min-width: 90px;
                                min-height: 50px;
                                border: 1px solid #e4e5e7;
                                border-radius: 4px;
                                padding: 4px 4.25px;
                                cursor: pointer;
                                .summary-skeleton {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-between;
                                    align-items: stretch;
                                    height: 100%;
                                    .summary-skeleton--header {
                                        width: 35%;
                                        height: 10px;
                                        border-radius: 3px;
                                        background-color: #e4e5e7;
                                    }
                                    .summary-skeleton--body {
                                        flex: 1;
                                        margin-left: 3px;
                                        min-height: 100%;
                                        border-radius: 3px;
                                        background-color: #f9f9f9;
                                    }
                                }
                                &.box-enable {
                                    border-color: #2c6ecb;
                                    background-color: #f2f7fe;
                                    .summary-skeleton {
                                        .summary-skeleton--header {
                                            background-color: #2c6ecb;
                                        }
                                        .summary-skeleton--body {
                                            background-color: #c9dfff;
                                        }
                                    }
                                }
                            }

                            #setting-widget-summary--top {
                                min-width: 90px;
                                min-height: 50px;
                                border: 1px solid #e4e5e7;
                                border-radius: 4px;
                                padding: 4px 4.25px;
                                cursor: pointer;
                                .summary-skeleton {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    .summary-skeleton--header {
                                        height: 10px;
                                        border-radius: 3px;
                                        background-color: #e4e5e7;
                                    }
                                    .summary-skeleton--body {
                                        margin-top: 5px;
                                        min-height: 25px;
                                        border-radius: 3px;
                                        background-color: #f9f9f9;
                                    }
                                }
                                &.box-enable {
                                    border-color: #2c6ecb;
                                    background-color: #f2f7fe;
                                    .summary-skeleton {
                                        .summary-skeleton--header {
                                            background-color: #2c6ecb;
                                        }
                                        .summary-skeleton--body {
                                            background-color: #c9dfff;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .color-schema {
                        span {
                            &.Polaris-Icon {
                                margin: 0;
                            }
                        }
                        .color-picker--detail {
                            border: 1px solid var(--p-color-border);
                            padding: 12px;
                            border-radius: 8px;
                            h5 {
                                &.Polaris-Text--root.Polaris-Text--semibold {
                                    margin: 7px 0 10px;
                                }
                            }
                        }
                    }

                    .add-app-block-guild--popover {
                        padding: 0px 16px;
                    }

                    .setting-review-display--tab {
                        & > .Polaris-Box {
                            height: 100%;
                            & > .Polaris-InlineStack {
                                height: 100%;
                            }
                        }
                    }
                    .color-mode-option--field {
                        width: 100%;
                        max-width: 90px;
                        cursor: pointer;
                        .color-mode-option-skeleton--container {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 6px;
                            padding: 7px 9px;
                            border-radius: 6px;
                            .color-mode-option-skeleton-item {
                                border-radius: 2px;
                                height: 8px;
                                &.small {
                                    display: block;
                                    width: 8px;
                                }
                                &.medium-1 {
                                    display: block;
                                    width: 52px;
                                }
                                &.medium-2 {
                                    display: block;
                                    width: 40px;
                                }
                                &.large {
                                    display: block;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                span {
                    &.Polaris-Icon {
                        margin: 0;
                    }
                }
            }

            .review-display-demo--field {
                overflow: hidden;
                height: 100%;
                max-height: 1080px;
                .hidden-element {
                    display: none !important;
                }
                .star-icon {
                    &--box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &--empty {
                        path {
                            fill: #e2e2e2;
                        }
                    }
                    path {
                        fill: var(--star_icon_color) !important;
                    }
                }
                button {
                    background-color: var(--primary_color) !important;
                    color: var(--primary_color) !important;
                }
                & > div.Polaris-Box:first-child {
                    height: 100%;
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    @media (max-width: 599px) {
                        border-radius: 0 !important;
                    }
                    .demo-container {
                        height: 100%;
                        overflow-y: auto;
                        -ms-overflow-style: none;
                        scrollbar-width: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .setting-widget-body-right--header {
                            position: sticky;
                            position: -webkit-sticky;
                            top: 0;
                            background-color: #ffffff;
                            z-index: 10;
                            @media (max-width: 599px) {
                                background-color: #f1f2f4;
                            }
                        }
                        .setting-widget-body-right--body {
                            background-color: #f6f6f7;
                            @media (max-width: 599px) {
                                background-color: #f1f2f4;
                            }
                            .demo-field {
                                margin-bottom: 40px !important;
                                padding: 10px 30px 40px;
                                background-color: #ffffff;
                                @media (max-width: 1039px) {
                                    padding: 10px 10px 40px;
                                    & > div {
                                        padding-bottom: 70px;
                                    }
                                }
                                .title {
                                    font-size: 18px;
                                    font-weight: 500;
                                    line-height: 24px;
                                    margin-bottom: 10px;
                                    color: var(--title_text_color);
                                }
                                .sub-title {
                                    color: var(
                                        --secondary_text_color
                                    ) !important;
                                    font-size: 12px;
                                    margin-bottom: 5px;
                                    font-weight: 500;
                                    line-height: 143%;
                                    text-align: center;
                                }
                                &.mobile {
                                    width: 430px;
                                    margin: auto;
                                    padding: 20px 14px 20px 15px;
                                    border: 1px solid #e2e2ee;
                                    border-radius: 10px;
                                    .filter-tab--list {
                                        .tab-item {
                                            font-size: 14px !important;
                                            line-height: 16px !important;
                                            padding: 10px;
                                        }
                                    }
                                    @media (max-width: 599px) {
                                        width: 100%;
                                        max-width: 430px;
                                        min-width: 220px;
                                    }

                                    .style-grid {
                                        .list-review {
                                            grid-template-columns: 1fr 1fr;
                                            @media (max-width: 390px) {
                                                grid-template-columns: 1fr;
                                            }
                                        }
                                        .review-item {
                                            border-bottom: 1px solid
                                                var(--line_color);
                                            overflow: hidden;
                                        }
                                    }
                                }
                                &.desktop,
                                &.max {
                                    .trustshop-layout-box--left {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        @media (min-width: 1040px) and (max-width: 1160px) {
                                            flex-direction: column;
                                            .widget-body {
                                                width: 100% !important;
                                            }
                                            .style-left {
                                                margin-right: 0 !important;
                                                width: 100% !important;
                                                max-width: 100% !important;
                                                .summarybox-right {
                                                    .write-review-btn {
                                                        width: fit-content !important;
                                                        margin: auto;
                                                    }
                                                }
                                            }
                                        }
                                        @media (max-width: 980px) {
                                            .widget-body {
                                                width: 100% !important;
                                            }
                                            .style-left {
                                                margin-right: 0 !important;
                                                width: 100% !important;
                                                max-width: 100% !important;
                                                .summarybox-right {
                                                    .write-review-btn {
                                                        width: fit-content !important;
                                                        margin: auto;
                                                    }
                                                }
                                            }
                                            flex-direction: column;
                                        }
                                        .widget-header {
                                            height: fit-content;
                                            margin: 0;
                                        }
                                        .widget-body {
                                            width: calc(100% - 370px);
                                        }
                                    }
                                    .classify-tab {
                                        flex-wrap: wrap !important;
                                    }
                                }

                                .widget-header {
                                    display: flex;
                                    margin: auto;
                                    .overview {
                                        .avg-rating {
                                            text-align: center;
                                            font-size: 44px;
                                            font-weight: 700;
                                            line-height: 58px;
                                            margin: auto;
                                            display: flex;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: center;
                                            color: var(--primary_color);
                                            span {
                                                svg {
                                                    width: 27px;
                                                    height: 27px;
                                                }
                                            }
                                        }
                                        .summary-rating--star {
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            gap: 4px;
                                            margin-bottom: 10px;
                                            .star-icon {
                                                &:last-child {
                                                    path {
                                                        fill: #e2e2e2 !important;
                                                    }
                                                }
                                            }
                                        }
                                        .total-review {
                                            font-size: 16px;
                                            line-height: 100%;
                                            font-weight: 400;
                                            text-align: center;
                                            color: var(
                                                --primary_text_color
                                            ) !important;
                                        }
                                    }
                                    .score-item {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        align-items: center;
                                        justify-content: center;
                                        gap: 5px;
                                        span {
                                            color: var(
                                                --rating_process_color
                                            ) !important;
                                            svg {
                                                width: 13px;
                                                height: 13px;
                                            }
                                        }
                                        .score-bar {
                                            width: 90%;
                                            min-width: 110px;
                                            height: 8px;
                                            border-radius: 5px;
                                            background-color: var(
                                                --rating_process_empty_color
                                            );
                                        }
                                    }
                                    .summarybox-right {
                                        .write-review-btn--container {
                                            border-top: 1px solid
                                                var(--line_color);
                                            padding: 20px;
                                            .title {
                                                text-align: center;
                                                margin: 0 0 5px !important;
                                            }
                                            &.no-border {
                                                padding-top: 0;
                                            }
                                            .write-review-btn {
                                                display: block;
                                                text-align: center;
                                                width: 100%;
                                                min-width: 185px;
                                                min-height: 38px;
                                                font-size: 14px;
                                                line-height: 20px;
                                                letter-spacing: 0.6px;
                                                padding: 0px;
                                                white-space: nowrap;
                                                background-color: var(
                                                    --primary_color
                                                ) !important;
                                                color: var(
                                                    --secondary_color
                                                ) !important;
                                                border-radius: 5px;
                                                @media (max-width: 450px) {
                                                    width: 100% !important;
                                                }
                                            }
                                        }
                                    }

                                    &.header-desktop,
                                    &.header-max {
                                        &.style-top {
                                            flex-direction: column;
                                            .summary-box {
                                                margin-bottom: 20px;
                                                width: 100%;
                                                border: 1px solid
                                                    var(--line_color);
                                                border-radius: 4px;
                                                padding: 30px 40px;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                justify-content: space-evenly;
                                                align-items: center;
                                                .summarybox-left {
                                                    width: 60%;
                                                    display: flex;
                                                    flex-direction: row;
                                                    flex-wrap: nowrap;
                                                    justify-content: space-evenly;
                                                    align-items: center;
                                                    border-right: 1px solid
                                                        var(--line_color);
                                                    @media (max-width: 768px) {
                                                        width: 100% !important;
                                                        border: none;
                                                        .score-list {
                                                            width: 100% !important;
                                                            min-width: 120px;
                                                        }
                                                    }
                                                    @media (max-width: 390px) {
                                                        flex-direction: column;
                                                        .score-list {
                                                            width: 100%;
                                                        }
                                                    }

                                                    &.no_write_review_btn {
                                                        @media (max-width: 850px) {
                                                            width: 100%;
                                                            border: none;
                                                        }
                                                    }

                                                    .score-list {
                                                        width: 50%;
                                                        min-width: 120px;
                                                        display: flex;
                                                        flex-direction: column;
                                                        gap: 4px;
                                                    }
                                                }

                                                .summarybox-right {
                                                    .write-review-btn--container {
                                                        border: none !important;
                                                    }
                                                    .title {
                                                        text-align: center;
                                                        margin-top: 0 !important;
                                                        padding: 0;
                                                    }

                                                    .write-review-btn--empty {
                                                        min-width: 172px;
                                                        @media (max-width: 850px) {
                                                            display: none;
                                                        }
                                                    }
                                                }

                                                @media (max-width: 768px) {
                                                    flex-direction: column;
                                                    padding: 20px;
                                                }
                                            }
                                        }
                                        &.style-left {
                                            max-width: 340px;
                                            margin-right: 30px;
                                            flex-shrink: 0;
                                            .header--left {
                                                width: 100%;
                                                padding: 0;
                                                border: 1px solid
                                                    var(--line_color);
                                                border-radius: 4px;
                                                flex-shrink: 0;
                                                .title {
                                                    margin: 0 0 14px;
                                                    text-align: center;
                                                }

                                                .summarybox-left {
                                                    display: flex;
                                                    flex-direction: row;
                                                    flex-wrap: nowrap;
                                                    gap: 20px;
                                                    align-items: center;
                                                    padding: 20px 16px;
                                                    @media (max-width: 390px) {
                                                        flex-direction: column;
                                                        .score-list {
                                                            width: 100%;
                                                        }
                                                    }
                                                    .score-list {
                                                        flex: 1;
                                                        display: flex;
                                                        flex-direction: column;
                                                        gap: 4px;
                                                    }
                                                    &.no_write_review_btn {
                                                        margin: 0 !important;
                                                    }
                                                }
                                            }
                                            .header--right {
                                                overflow: hidden;
                                                .title {
                                                    margin: 0 0 14px;
                                                }
                                            }
                                            @media (min-width: 1040px) and (max-width: 1200px) {
                                                flex-direction: column;
                                            }

                                            @media (min-width: 768px) and (max-width: 940px) {
                                                flex-direction: column;
                                            }

                                            @media (min-width: 599px) and (max-width: 670px) {
                                                flex-direction: column;
                                            }
                                        }

                                        &.one-page-style--left {
                                            width: 50%;
                                            margin: 0;
                                            .score-list {
                                                width: 70% !important;
                                                display: flex;
                                                flex-direction: column;
                                                gap: 4px;
                                            }
                                            @media (max-width: 1000px) {
                                                width: 100%;
                                            }
                                        }
                                    }
                                    &.header-mobile {
                                        display: flex;
                                        flex-direction: column;
                                        .summary-box {
                                            padding: 10px 10px 20px;
                                            border-radius: 4px;
                                            border: 1px solid var(--line_color);
                                            margin-bottom: 30px;
                                            .summarybox-left {
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                justify-content: center;
                                                align-items: center;
                                                gap: 20px;
                                                padding-bottom: 20px;
                                                @media (max-width: 390px) {
                                                    flex-direction: column;
                                                    .score-list {
                                                        width: 100%;
                                                    }
                                                }
                                                .overview {
                                                    display: flex;
                                                    flex-direction: column;
                                                    justify-content: center;
                                                    align-items: center;
                                                    margin: 10px 0;
                                                }
                                            }
                                            .write-review-btn {
                                                padding: 7px 0;
                                                font-size: 14px;
                                                line-height: 20px;
                                            }
                                            .title {
                                            }
                                        }
                                    }
                                }

                                .widget-body {
                                    .review-item--right {
                                        padding-top: 20px;
                                        display: flex;
                                        flex-direction: column;
                                        gap: 12px;
                                    }
                                    .image-from-review--container {
                                        .image-from-review--wrap {
                                            position: relative;
                                            width: 100%;
                                            margin-bottom: 30px;
                                            .button-slide {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                position: absolute;
                                                top: 50%;
                                                width: 38px;
                                                height: 38px;
                                                border: none;
                                                border-radius: 50%;
                                                z-index: 9;
                                                box-shadow: 0px 4px 4px 0px
                                                    #00000026;
                                                background-color: var(
                                                    --slide_button
                                                ) !important;
                                                &.button-left {
                                                    left: 0;
                                                    transform: translate(
                                                        -50%,
                                                        -50%
                                                    );
                                                }
                                                &.button-right {
                                                    right: 0;
                                                    transform: translate(
                                                        50%,
                                                        -50%
                                                    );
                                                }
                                            }
                                            .image-from-review--list__wrap {
                                                overflow: hidden;
                                            }
                                            .image-from-review--list {
                                                width: fit-content;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                justify-content: space-between;
                                                position: relative;
                                                .image-from-review--item {
                                                    margin-right: 12px;
                                                    border-radius: 4px;
                                                    overflow: hidden;
                                                    width: 92px;
                                                    height: 91px;
                                                    img {
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                        aspect-ratio: 1/1;
                                                    }
                                                    &:last-child {
                                                        margin: 0;
                                                    }
                                                    position: relative;
                                                    .overlay {
                                                        position: absolute;
                                                        bottom: 6px;
                                                        right: 6px;
                                                        border-radius: 3px;
                                                        display: flex;
                                                        flex-direction: row;
                                                        flex-wrap: nowrap;
                                                        align-items: center;
                                                        justify-content: flex-end;
                                                        padding: 0 5px;
                                                        font-size: 16px;
                                                        min-height: 24px;
                                                        background-color: rgba(
                                                            0,
                                                            0,
                                                            0,
                                                            0.6
                                                        );
                                                        svg {
                                                            width: 14px;
                                                            height: 14px;
                                                            margin-right: 3px;
                                                        }
                                                        span {
                                                            color: white !important;
                                                        }
                                                        &.video--overlay {
                                                            top: 50%;
                                                            left: 50%;
                                                            transform: translate(
                                                                -50%,
                                                                -50%
                                                            );
                                                            width: 44px;
                                                            height: 44px;
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            border-radius: 50%;
                                                            svg {
                                                                margin: 0;
                                                            }
                                                        }
                                                    }

                                                    .image-from-review--overlay {
                                                        background-color: var(
                                                            --primary_color
                                                        ) !important;
                                                        opacity: 0.8;
                                                        span {
                                                            color: var(
                                                                --secondary_color
                                                            ) !important;
                                                        }
                                                        svg {
                                                            path {
                                                                fill: var(
                                                                    --secondary_color
                                                                ) !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .filter-container {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        flex-wrap: wrap;
                                        gap: 10px;
                                        .tab-item {
                                            border: none;
                                            padding: 9px 10px;
                                            border: none;
                                            border-radius: 4px;
                                            color: var(
                                                --filter_sort_text
                                            ) !important;
                                            background-color: var(
                                                --filter_sort_background
                                            ) !important;
                                            font-size: 14px;
                                            font-weight: 500;
                                            line-height: 100%;
                                            word-break: keep-all;
                                            white-space: nowrap;
                                            text-align: center;
                                            &:first-child {
                                                color: var(
                                                    --secondary_color
                                                ) !important /**/;
                                                background-color: var(
                                                    --primary_color
                                                ) !important;
                                                svg {
                                                    path {
                                                        fill: #ffffff !important;
                                                    }
                                                }
                                            }
                                            svg {
                                                margin-right: 2px;
                                                width: 10px;
                                                height: 10px;
                                                path {
                                                    fill: var(
                                                        --filter_sort_text
                                                    ) !important;
                                                }
                                            }
                                            &:last-child {
                                                margin-right: 0;
                                            }
                                        }
                                        .filter-tab--list {
                                            display: flex;
                                            flex-wrap: wrap;
                                            gap: 10px;
                                            .tab-item {
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-items: center;
                                            }
                                        }

                                        .sort--container {
                                            flex: 1;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: flex-end;
                                            gap: 10px;
                                            white-space: nowrap;
                                            span {
                                                font-size: 14px;
                                                font-weight: 400;
                                                &.sort-by {
                                                    color: var(
                                                        --secondary_text_color
                                                    );
                                                }
                                            }
                                            .sort--selector {
                                                cursor: pointer;
                                                background-color: var(
                                                    --filter_sort_background
                                                ) !important;
                                                color: var(
                                                    --filter_sort_text
                                                ) !important;
                                                padding: 6px 10px;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                gap: 12px;
                                                position: relative;
                                                &:hover {
                                                    .sort--selector__list {
                                                        display: flex;
                                                    }
                                                }
                                                .sort--selector__list {
                                                    position: absolute;
                                                    width: 100%;
                                                    z-index: 99;
                                                    right: 0;
                                                    top: calc(100% + 4px);
                                                    display: none;
                                                    flex-direction: column;
                                                    gap: 4px;
                                                    background-color: var(
                                                        --filter_sort_background
                                                    );
                                                    padding: 6px;
                                                    box-shadow: 0 4px 6px -2px rgba(26, 26, 26, 0.2),
                                                        inset 0 1px 0 0
                                                            hsla(
                                                                0,
                                                                0%,
                                                                80%,
                                                                0.5
                                                            ),
                                                        inset 0 -1px 0 0 rgba(0, 0, 0, 0.17),
                                                        inset -1px 0 0 0 rgba(0, 0, 0, 0.13),
                                                        inset 1px 0 0 0
                                                            rgba(0, 0, 0, 0.13);
                                                    border-radius: 12px;
                                                    .sort-selector--item {
                                                        padding: 6px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .review-item--avt {
                                        margin-right: 6px;
                                        width: 35px;
                                        height: 35px;
                                        border-radius: 50%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        overflow: hidden;
                                        p {
                                            font-size: 14px;
                                            font-weight: 600;
                                            line-height: 100%;
                                            color: #000000 !important;
                                        }
                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                    .customer-info {
                                        height: fit-content;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        align-items: center;
                                        gap: 8px;
                                        .customer-name {
                                            font-size: 14px;
                                            font-weight: 500;
                                            line-height: 16px;
                                            color: var(
                                                --user_name_color
                                            ) !important;
                                        }
                                        .verify-buyer {
                                            font-size: 10px;
                                            font-weight: 500;
                                            line-height: 12px;
                                            color: #028837 !important;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;

                                            .checked-verify {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                width: 10px;
                                                height: 10px;
                                                border-radius: 50%;
                                                background-color: #028837;
                                                margin-right: 4px;
                                            }
                                        }
                                        div {
                                            p {
                                                &:last-child {
                                                    display: flex;
                                                    flex-direction: row;
                                                    flex-wrap: nowrap;
                                                    align-items: center;
                                                    span {
                                                        &:first-child {
                                                            display: block;
                                                            width: 10px;
                                                            height: 10px;
                                                            padding: 3px;
                                                            background-color: var(
                                                                --secondary_text_color
                                                            ) !important;
                                                            border-radius: 50%;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-self: center;
                                                            margin-right: 2px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .star-rating {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        flex-wrap: wrap;
                                        .star {
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            margin-right: 8px;
                                            .star-icon--box {
                                                margin-right: 2px;
                                                display: flex;
                                            }
                                        }
                                    }
                                    .review-time {
                                        display: inline-block;
                                        font-size: 14px;
                                        line-height: 22px;
                                        font-weight: 400;
                                        color: var(
                                            --secondary_text_color
                                        ) !important;
                                    }
                                    .review-title {
                                        font-size: 14px;
                                        font-weight: 600;
                                        line-height: 20px;
                                        margin: 0;
                                        margin-left: 2px;
                                        word-break: break-all;
                                        white-space: pre-wrap;
                                        color: var(--primary_color);
                                    }
                                    .review-media--container {
                                        width: 100%;
                                        overflow: hidden;
                                    }
                                    .review-media {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        overflow: hidden;
                                        width: fit-content;
                                        margin-top: 10px;
                                        .media-item {
                                            width: 72px;
                                            height: 72px;
                                            border-radius: 4px;
                                            margin-right: 10px;
                                            overflow: hidden;
                                            position: relative;
                                            margin-bottom: 10px;
                                            &:last-child {
                                                margin: 0;
                                            }
                                            .overlay {
                                                position: absolute;
                                                bottom: 6px;
                                                right: 6px;
                                                border-radius: 3px;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-items: center;
                                                justify-content: flex-end;
                                                padding: 0 5px;
                                                font-size: 16px;
                                                min-height: 24px;
                                                background-color: rgba(
                                                    0,
                                                    0,
                                                    0,
                                                    0.6
                                                );
                                                svg {
                                                    width: 14px;
                                                    height: 14px;
                                                    margin-right: 3px;
                                                }
                                                span {
                                                    color: white !important;
                                                }
                                                &.video--overlay {
                                                    top: 50%;
                                                    left: 50%;
                                                    transform: translate(
                                                        -50%,
                                                        -50%
                                                    );
                                                    width: 44px;
                                                    height: 44px;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    border-radius: 50%;
                                                    svg {
                                                        margin: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .review-content {
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: var(
                                            --primary_text_color
                                        ) !important;
                                    }
                                    .review-item--footer {
                                        margin-top: 2px;
                                        .helpful-count {
                                            font-size: 11px;
                                            color: var(
                                                --secondary_text_color
                                            ) !important;
                                        }
                                        .helpful-container {
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: stretch;
                                            .divider {
                                                display: block;
                                                width: 1px;
                                                background-color: var(
                                                    --line_color
                                                );
                                            }
                                            span {
                                                font-size: 14px;
                                                letter-spacing: 0.6px;
                                                font-weight: 400;
                                                line-height: 133.4%;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-items: center;
                                                gap: 4px;
                                                &.helpful--btn {
                                                    border: 1px solid #dedfe6;
                                                    background-color: #ffffff;
                                                    border-radius: 4px;
                                                    margin-right: 20px;
                                                    padding: 8px;
                                                    color: #1a1a1a !important;
                                                }
                                                &:last-child {
                                                    padding: 0 20px;
                                                    color: var(
                                                        --primary_text_color
                                                    );
                                                }
                                            }
                                        }
                                    }
                                    .loadmore-review-btn {
                                        padding: 9px 25px;
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 20px;
                                        border: none;
                                        border-radius: 6px;
                                        margin-top: 26px;
                                        position: relative;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        color: var(
                                            --load_more_color
                                        ) !important;
                                        background-color: transparent !important;
                                        border: 1px solid var(--load_more_color);
                                    }
                                    &.body-desktop,
                                    &.body-max {
                                        &.style-list {
                                            .list-review {
                                                display: flex;
                                                flex-direction: column;
                                                .review-item {
                                                    .review-wrap {
                                                        padding: 26px 0 20px;
                                                        border-bottom: 1px solid
                                                            var(--line_color);
                                                        @media (max-width: 650px) {
                                                            flex-direction: column;
                                                        }
                                                        .review-item--left {
                                                            height: fit-content;
                                                            display: flex;
                                                            flex-direction: row;
                                                            flex-wrap: nowrap;
                                                            align-items: center;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &.style-grid {
                                            .list-review {
                                                margin-top: 10px;
                                                display: grid;
                                                gap: 20px;
                                                grid-template-columns: repeat(
                                                    auto-fill,
                                                    minmax(340px, 1fr)
                                                );
                                                grid-auto-rows: 1px;
                                                .review-item {
                                                    border-bottom: 1px solid
                                                        var(--line_color);
                                                    .review-wrap {
                                                        vertical-align: top;
                                                        display: inline-block;
                                                        padding: 10px 5px;
                                                        height: fit-content;
                                                        width: 100%;
                                                        overflow: hidden;
                                                        .review-item--header {
                                                            display: flex;
                                                            flex-direction: row;
                                                            align-items: center;
                                                        }
                                                        .review-item--body {
                                                            padding-top: 20px;
                                                            display: flex;
                                                            flex-direction: column;
                                                            gap: 12px;
                                                            .star-rating {
                                                                flex-wrap: wrap;
                                                            }
                                                            .review-title {
                                                                margin: 0;
                                                            }
                                                            .review-media {
                                                                .media-item {
                                                                    height: auto;
                                                                    max-width: 72px;
                                                                    max-height: 72px;
                                                                    aspect-ratio: 1/1;
                                                                    &:nth-child(
                                                                            2
                                                                        ) {
                                                                        display: none;
                                                                    }

                                                                    .video-overlay {
                                                                        svg {
                                                                            width: 10px;
                                                                        }
                                                                        p {
                                                                            font-size: 8px;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    &.body-mobile {
                                        &.style-grid {
                                            .list-review {
                                                grid-template-columns: repeat(
                                                    auto-fill,
                                                    minmax(180px, 1fr)
                                                );
                                            }
                                        }
                                        .review-item {
                                            .review-wrap {
                                                display: flex;
                                                flex-direction: column;
                                                padding: 10px 0;
                                                .review-item--header {
                                                    display: flex;
                                                    flex-wrap: nowrap;
                                                    align-items: center;
                                                    .review-item--avt {
                                                        width: 35px;
                                                        height: 35px;
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        overflow: hidden;
                                                        p {
                                                            font-size: 14px;
                                                            font-weight: 600;
                                                            line-height: 100%;
                                                        }
                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            object-fit: cover;
                                                        }
                                                    }
                                                    .customer-info {
                                                        display: flex;
                                                        flex-direction: row;
                                                        flex-wrap: nowrap;
                                                        align-items: center;
                                                        gap: 8px;
                                                        .customer-name {
                                                            font-size: 16px;
                                                            line-height: 100%;
                                                            color: var(
                                                                --user_name_color
                                                            ) !important;
                                                        }
                                                        .verify-buyer {
                                                            font-size: 12px;
                                                            line-height: 14px;
                                                            letter-spacing: 0.14px;
                                                        }
                                                    }
                                                }
                                                .review-item--body {
                                                    .star-rating {
                                                        justify-content: space-between;
                                                        .star {
                                                            svg {
                                                                width: 16px;
                                                                height: 16px;
                                                            }
                                                        }
                                                        .review-time {
                                                            font-size: 12px;
                                                            line-height: 17px;
                                                        }
                                                    }
                                                    .review-title {
                                                        margin: 0;
                                                        font-size: 16px;
                                                        line-height: 22.88px;
                                                        letter-spacing: 0.17px;
                                                    }
                                                    .review-content {
                                                        letter-spacing: 0.17px;
                                                    }
                                                }
                                            }
                                        }
                                        .loadmore-review-btn {
                                            font-size: 14px;
                                            line-height: 20px;
                                            white-space: nowrap;
                                        }
                                        &.style-grid {
                                            .list-review {
                                                margin-top: 10px;
                                                display: grid;
                                                gap: 10px;
                                                grid-auto-rows: 1px;
                                                .review-item {
                                                    .review-wrap {
                                                        display: flex;
                                                        flex-direction: column;
                                                        padding: 20px 0;
                                                        .review-item--header {
                                                            display: flex;
                                                            align-items: center;
                                                            flex-wrap: nowrap;
                                                            .review-item--avt {
                                                                width: 35px;
                                                                height: 35px;
                                                                display: flex;
                                                                justify-content: center;
                                                                align-items: center;
                                                                overflow: hidden;
                                                                p {
                                                                    font-size: 14px;
                                                                    font-weight: 600;
                                                                    line-height: 100%;
                                                                }
                                                                img {
                                                                    width: 100%;
                                                                    height: 100%;
                                                                    object-fit: cover;
                                                                }
                                                            }
                                                            .customer-info {
                                                                display: flex;
                                                                flex-direction: row;
                                                                flex-wrap: nowrap;
                                                                align-items: center;
                                                                gap: 8px;
                                                                .customer-name {
                                                                    font-size: 16px;
                                                                    line-height: 100%;
                                                                    color: var(
                                                                        --user_name_color
                                                                    ) !important;
                                                                }
                                                                .verify-buyer {
                                                                    font-size: 12px;
                                                                    line-height: 14px;
                                                                    letter-spacing: 0.14px;
                                                                }
                                                            }
                                                        }
                                                        .review-item--body {
                                                            .star-rating {
                                                                justify-content: space-between;
                                                                .star {
                                                                    svg {
                                                                        width: 16px;
                                                                        height: 16px;
                                                                    }
                                                                }
                                                                .review-time {
                                                                    font-size: 12px;
                                                                    line-height: 17px;
                                                                }
                                                            }
                                                            .review-title {
                                                                margin: 0;
                                                                font-size: 16px;
                                                                line-height: 22.88px;
                                                                letter-spacing: 0.17px;
                                                            }
                                                            .review-content {
                                                                letter-spacing: 0.17px;
                                                            }
                                                            .review-media {
                                                                display: flex;
                                                                // grid-template-columns: repeat(
                                                                //     auto-fill,
                                                                //     72.92px
                                                                // );
                                                                // grid-template-rows: 72.92px;
                                                                gap: 8.89px;
                                                                .media-item {
                                                                    width: 72.92px;
                                                                    height: 72.92px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.style-list {
                                            .list-review {
                                                .review-item {
                                                    border-bottom: 1px solid
                                                        var(--line_color);
                                                    display: flex;
                                                    flex-direction: column;
                                                    .review-item--header {
                                                        display: flex;
                                                        flex-wrap: nowrap;
                                                    }
                                                    .review-item--body {
                                                        .star-rating {
                                                            justify-content: space-between;
                                                        }
                                                        .review-title {
                                                            margin: 0;
                                                        }
                                                        .review-media {
                                                            .media-item {
                                                                margin-right: 8.89px;
                                                                width: 80px;
                                                                height: 80px;
                                                                &:last-child {
                                                                    margin-right: 0;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .page-no-review--demo {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                gap: 44px;

                                .page-no-review-demo--header {
                                    display: block;
                                    background: var(--linear-color--header);
                                    min-height: 50px;
                                }
                                .page-no-review-demo--footer {
                                    display: block;
                                    background: var(--linear-color--footer);
                                    min-height: 50px;
                                }
                                .page-no-review-demo--content {
                                    padding: 44px 46px;
                                    .page-no-review--container {
                                        border: 1px solid var(--border_color);
                                        border-radius: 4px;
                                        padding: 50px 0;
                                        .page-no-review--wrap {
                                            margin: 0 auto;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                            align-items: center;
                                            gap: 16px;
                                            .section-title--wrap {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                gap: 2px;
                                                .section-title {
                                                    font-size: 24px;
                                                    font-weight: 500;
                                                    color: var(
                                                        --section_title_color
                                                    );
                                                    text-align: center;
                                                    max-width: 300px;
                                                    margin-bottom: 4px;
                                                }
                                                .section--divider {
                                                    display: block;
                                                    border: 1px solid
                                                        var(--border_color);
                                                }
                                                .section--star {
                                                    display: flex;
                                                    flex-direction: row;
                                                    flex-wrap: nowrap;
                                                    gap: 5px;
                                                    align-items: stretch;
                                                }
                                            }
                                            .text-no-reviews {
                                                font-size: 18px;
                                                font-weight: 500;
                                                line-height: 133.4%;
                                                margin: 0;
                                                color: var(
                                                    --text_no_review_color
                                                );
                                            }
                                            .call-to-action {
                                                padding: 10px 15px;
                                                border-radius: 4px;
                                                background-color: var(
                                                    --call_to_action_color
                                                );
                                                .call-to-action--text {
                                                    margin: 0;
                                                    font-size: 14px;
                                                    margin: 0;
                                                    line-height: 20px;
                                                    font-weight: 500;
                                                    color: var(
                                                        --call_to_text_color
                                                    );
                                                }
                                            }
                                        }
                                    }
                                }
                                &.mobile {
                                    width: 430px;
                                    margin: auto;
                                    border: 1px solid #e2e2ee;
                                    border-radius: 10px;
                                    .page-no-review-demo--content {
                                        padding: 30px 15px;
                                        .page-no-review--container {
                                            padding: 50px 10px;
                                            .section-title--wrap {
                                                display: flex !important;
                                                flex-direction: column;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-rating--container {
            height: 100%;
            max-height: 1080px;
            & > .Polaris-InlineGrid {
                height: 100%;
                overflow: hidden;
            }
            .product-rating-setting--field {
                border-right: 1px solid #ebecee;
                height: 100%;
                max-height: 1080px;
                padding-bottom: 16px;
                overflow: auto;
                scrollbar-width: none;
                background-color: #ffffff;
                transition: height 0.3s linear;
                &.hide {
                    height: 53px;
                    @media (min-width: 1040px) {
                        height: 100%;
                    }
                }
                @media (max-width: 1039px) {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 50vh;
                    z-index: 399;
                    border-top: 1px solid #ebecee;
                    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
                }
                &::-webkit-scrollbar {
                    display: none;
                }
                &::-webkit-scrollbar {
                    display: none;
                }
                .product-rating-setting--item {
                    border-bottom: 0.5px solid #e3e3e3;
                    padding: 16px 0;
                    &.no-border {
                        border: 0;
                    }
                    .text-field--wrap {
                        min-width: 100px;
                        max-width: 100px;
                        @media (min-width: 65em) and (max-width: 89.9975em),
                            (max-width: 490px) {
                            width: 100%;
                            max-width: unset;
                        }
                    }
                    .setting--option {
                        min-width: 156px;
                        .avg-rating {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 100%;
                        }
                        .total-ratings {
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 20px;
                            white-space: nowrap;
                        }
                        input[type="checkbox"] {
                            margin: 0;
                            margin-right: 8px;
                            width: 18px;
                            height: 18px;
                        }

                        label.Polaris-Choice.Polaris-RadioButton__ChoiceLabel {
                            & > span.Polaris-Choice__Label {
                                flex: 1;
                                justify-content: space-between;
                            }
                        }
                    }
                    .error-message {
                        margin: 0;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        grid-area: 8px;
                        span {
                            color: var(--p-color-text-critical);
                            .Polaris-Icon {
                                margin: 0;
                                svg {
                                    color: var(--p-color-text-critical);
                                }
                            }
                        }
                    }
                }
            }

            .product-rating-demo--field {
                max-height: 100%;
                border-radius: 8px;
                background-color: #ffffff;
                border: 1px solid #ebecee;
                box-shadow: 0px 0px 5px 0px #0000000d;
                overflow: auto;
                scrollbar-width: none;
                width: fit-content;
                max-width: 100%;
                margin: auto;
                @media (max-width: 768px) {
                    height: 100%;
                    padding-bottom: 20px;
                    .preview--wrap {

                        flex-wrap: wrap;
                    }
                }
                &::-webkit-scrollbar {
                    display: none;
                }
                .preview--wrap {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 24px;
                    padding: 20px;
                    border: 1px solid #e3e3e3;
                    border-radius: 12px;
                    
                    .preview-content--left {
                        width: 50%;
                        aspect-ratio: 1/1;
                        max-width: 225px;
                        position: relative;
                        overflow: hidden;
                        flex-shrink: 0;
                        img {
                            width: 100%;
                            height: auto;
                            position: absolute;
                        }
                        .preview--slide {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            position: relative;
                            .slide-btn {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                &.left {
                                    left: 5px;
                                }
                                &.right {
                                    right: 5px;
                                }
                            }
                        }
                    }

                    .preview-content--right {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        color: #3d4153;
                        .demo-product--name {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            color: #3d4153;
                        }
                        
                        .demo-product-size {
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            p {
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 100%;
                            }
                            div {
                                display: flex;
                                flex-direction: row;
                                gap: 4px;
                                span {
                                    width: 24px;
                                    height: 24px;
                                    border-radius: 4px;
                                    background-color: #f1f1f1;
                                }
                            }
                        }
                        .demo-product-price {
                            font-size: 11px;
                            font-weight: 650;
                            line-height: 12px;
                            color: #202223;
                        }
                        .demo-product--btn {
                            width: 100%;
                            height: 32px;
                            border-radius: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 11px;
                            line-height: 12px;
                            &.add-to--cart {
                                border: 1px solid #ebebeb;
                                font-weight: 450;
                                color: #000000;
                                background-color: #ffffff;
                            }

                            &.buy-it--now {
                                border: 1px solid #ebebeb;
                                font-weight: 650;
                                color: #ffffff;
                                background-color: #303030;
                            }
                        }
                    }
                }
                .collection-container {
                    .product-list {
                        display: grid;
                        grid-template-columns: repeat(
                            auto-fill,
                            minmax(var(--collection-card-item--size), 1fr)
                        );
                        gap: 20px;
                        width: 100%;
                        max-width: 658px;
                        .collection-product--item {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 16px;
                            padding: 12px;
                            border-radius: 8px;
                            border: 1px solid #e3e3e3;
                            img {
                                width: 100%;
                                height: auto;
                                border-radius: 6px;
                            }
                            .collection-product--info {
                                display: flex;
                                flex-direction: column;
                                gap: 6px;
                                flex: 1;
                                .collection-product--title {
                                    font-size: 14px;
                                    font-weight: 550;
                                    margin: 0;
                                    line-height: 20px;
                                    color: #3d4153;
                                }
                                .collection-product--rating {
                                    width: fit-content;
                                    .Polaris-InlineStack {
                                        width: fit-content;
                                    }
                                }

                                .star {
                                    &-icon--box {
                                        display: flex;
                                        svg {
                                            width: var(
                                                --collection-rating-star-size
                                            );
                                            height: var(
                                                --collection-rating-star-size
                                            );
                                        }
                                    }
                                    &-fill {
                                        path {
                                            fill: var(--rating-icon--color);
                                        }
                                    }
                                    &-empty {
                                        path {
                                            fill: #e2e2e2;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .rating--container {
                    .avg-rating {
                        font-size: 14px;
                        font-weight: 500;
                    }
                    .total-ratings {
                        font-size: 14px;
                        font-weight: 500;
                        white-space: nowrap;
                    }
                }
            }
        }

        .tab-active {
            background-color: var(--primary_color) !important;
            color: #ffffff !important;
        }

        .element_corners--square {
            border-radius: unset !important;
        }
        .button_corner_radius {
            border-radius: var(--button-corner-radius) !important;
        }
    }
    .add-app-block-guild--popover {
        padding-top: 16px;
        button {
            width: 100%;
        }
    }

    #add-app-block--guild {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: grid;
        place-content: center;
        background-color: #00000080;
        z-index: 999;
        .embbed-code--textfield {
            position: relative;
            #embbed-code--btn {
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                padding: var(--p-space-150) !important;
                z-index: 1000;
            }
        }
    }

    .desktop-hidden {
        @media (min-width: 1040px) {
            display: none;
        }
    }
    .mobile-hidden {
        @media (max-width: 599px) {
            display: none;
        }
    }

    .mobile-title {
        @media (max-width: 599px) {
            text-align: center;
            color: #000000 !important;
            font-size: 18px !important;
            font-weight: 500 !important;
            text-align: center;
        }
    }

    .hidden-element {
        display: none !important;
    }

    .on-site-displays__list-widget {
        .widget-install--button {
            svg {
                fill: #303030;
            }
            span {
                color: #303030;
            }
        }
    }

    .star-rating--wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 2px;
        svg {
            width: var(--product-rating-star-size);
            height: var(--product-rating-star-size);
        }
    }
}

.one-page--demo {
    .summarybox-left {
        padding-bottom: 0 !important;
    }
}

.color-picker--container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background-color: #ffffff;
    border: 2px solid #e1e3e5;
    border-radius: 5px;
    overflow: hidden;
    .color-picker--btn {
        flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 80%;
        padding: 5px;
        border: none;
        .color-picker {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            &.color-picker-detail--item {
                div {
                    width: 27px !important;
                    height: 27px !important;
                }
            }
            .color-code {
                font-size: 14px;
                line-height: 100%;
            }
        }
    }
    .color-alpha--attribute {
        text-align: right;
        min-width: 50px;
        padding: 8px;
        font-size: 14px;
    }
    &.disable {
        .color-picker--btn {
            .color-picker {
                div {
                    background-color: #e1e3e5 !important;
                }
            }
            .color-code {
                color: #e1e3e5;
            }
        }
        .color-alpha--attribute {
            color: #e1e3e5;
        }
    }
}

.discount-voucher--container {
    width: 100%;
    margin: auto;
    &.disable-setting {
        filter: grayscale(1);
        div,
        p,
        input,
        button,
        span {
            cursor: default !important;
        }
    }
    .create-discount--link {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    .discount-voucher--divider {
        width: 1px;
        background-color: #c9cccf;
    }

    .discount-voucher-verify-shopify--code {
        position: relative;
        .verify-shopify-code-success--icon {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            z-index: 99;
        }
    }

    .discount-value--toogle {
        .Polaris-Icon.Polaris-Icon--toneCritical {
            margin: 0;
            svg {
                color: var(--p-color-text-critical) !important;
            }
        }
    }

    .voucher-setting--container {
        // min-width: 60%;

        .Polaris-Icon {
            margin: 0;
        }

        .discount-voucher-choose--design {
            position: relative;
            padding: 80px 15px 15px;
            border-radius: 4px;
            &.before {
                background-color: #eef8f9;
                .choose-design--title {
                    background-color: #29a1a9;
                }
            }
            &.after {
                background-color: #eef2f9;
                .choose-design--title {
                    background-color: #2c6ecb;
                }
            }
            .choose-design--title {
                position: absolute;
                z-index: 99;
                top: 23px;
                margin: 0;
                padding-left: 25px;
                width: 265px;
                height: 30px;
                font-weight: 600;
                line-height: 30px;

                color: #ffffff;
                left: -5px;
                border-radius: 0px 15px 15px 0px;
            }

            .discount-type--row {
                width: 295px;
                .before-discount-skeleton--container {
                    .discount-skeleton--content {
                        width: 190px;
                        min-width: unset;
                    }
                    &::after {
                        left: 104px;
                    }
                    &::before {
                        left: 104px;
                    }
                }
            }
        }
    }
}
.customize-voucher--modal {
    .demo-voucher-container {
        min-width: 300px;
        width: 90%;
        max-width: 610px;
        #voucher-demo--container {
            min-width: 340px;
        }
        .voucher-demo--header {
            height: 120px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #2c7695;
            p {
                color: #ffffff;
                text-transform: uppercase;
            }
        }
        .voucher-demo--middle {
            padding: 24px 0 0;
            background-color: #ffffff;
            h1 {
                font-size: 24px;
                font-weight: 700;
                color: #000000;
                text-align: center;

                line-height: 100%;
            }
            .voucher-demo--content {
                p {
                    padding: 24px 20px 12px;
                    line-height: 24px;
                    h6 {
                        font-weight: 700;
                    }
                    span {
                        padding: 4px 12px;
                        background-color: #f6f6f7;
                        border: 1px solid #babfc3;
                        border-radius: 4px;
                    }
                }
            }

            .preview--review-box {
                .review-item {
                    padding: 16px 20px;
                    border: 1px solid;
                    border-color: #cccccc transparent;
                    display: grid;
                    gap: 8px;

                    &__header {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        .star-rates {
                            display: flex;
                            flex-direction: row;
                            .Polaris-Icon--toneBase {
                                svg {
                                    fill: #ffb400;
                                }
                            }
                        }
                        .review-item_time {
                            p {
                                margin: 0;
                                line-height: 100%;
                                font-size: 12px;
                                color: #97a2b3;
                                font-weight: 400;
                                margin-left: 5px;
                            }
                        }
                    }
                    &__body {
                        display: grid;
                        gap: 8px;
                        .review-item_title {
                            font-size: 12px;
                            font-weight: 700;
                            color: #000000;
                            opacity: 0.87;
                            line-height: 20px;
                            margin: 0;
                        }
                        .review-item_content {
                            margin: 0;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0.14px;
                        }
                        .review-item_media {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            img {
                                margin-right: 8px;
                                &:last-child {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
                .review-action {
                    margin-top: 10px;
                    text-align: center;
                    button {
                        font-size: 18px;
                        padding: 15px 30px;
                        border-radius: 100px;
                        background: #2c7695;
                        color: #fff;
                        box-shadow: none;
                        border: 0;
                    }
                }
            }
            .voucher-demo--product {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                margin-top: 19px;
                .voucher-demo-product--image {
                    width: 70px;
                    height: 70px;
                    background-color: #2c7695;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 25px;
                    p {
                        text-align: center;
                        color: #ffffff;
                    }
                }
                .voucher-demo-product--info {
                    .voucher-demo-product--name {
                        font-size: 14px;
                        line-height: 100%;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }
                    .voucher-demo-rate--item {
                        font-size: 12px;
                        line-height: 100%;
                        margin-bottom: 5px;
                    }
                    .voucher-demo-product--star {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;

                        svg {
                            width: 25px;
                            height: 25px;
                            margin-left: 7px;
                            &:first-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .voucher-demo--footer {
            height: 120px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p {
                color: #939393;
                span {
                    text-decoration: underline;
                }
            }
        }
    }

    .discount-voucher-secondary--text {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: #202223;
        &.disable {
            color: #6d7175;
        }
    }
    .discount-setting--field {
        height: 100%;
        max-height: 1080px;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        border-right: 1px solid #ebecee;
        background-color: #ffffff;
        transition: height 0.3s linear;
        &.hide {
            height: 53px;
            @media (min-width: 1040px) {
                height: 100%;
            }
        }
        @media (max-width: 1039px) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50vh;
            z-index: 399;
            border-top: 1px solid #ebecee;
            box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
        }
    }
    .voucher-setting--detail > .Polaris-InlineGrid {
        height: 100%;
        overflow: hidden;
    }
}

.customize-voucher--modal {
    #voucher-demo--container {
        position: relative;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000000;
            opacity: 0.2;
            z-index: 1;
        }
        .voucher-demo--product {
            background-color: white;
            margin-top: 0;
            padding-top: 20px;
        }
        .voucher-demo--voucher,
        .voucher-demo--product {
            position: relative;
            z-index: 2;
        }
    }
}

.discount-voucher-primary--text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #202223;
    &.disable {
        color: #6d7175;
    }
}
.voucher-setting--disable {
    filter: grayscale(1);
}

.before-discount-skeleton--container {
    border-radius: 7px;
    background-color: #29a1a9;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    .discount-percent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .discount-skeleton--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > p {
            font-size: 11.555px;
            font-weight: 500;
            line-height: 14px;
            text-align: center;
            margin-bottom: 14px;
        }

        .discount-skeleton--button {
            min-width: 140px;
            padding: 8px 10px;
            border-radius: 7px;
            background-color: #ffffff;

            p {
                margin: auto;
                color: #28556b;
                font-size: 11.555px;
                font-weight: 700;
                line-height: 16px;
                white-space: pre-wrap;
                text-align: center;
            }
        }
    }

    &::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #eef8f9;
        position: absolute;
    }

    &::after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #eef8f9;
        position: absolute;
    }
}

.after-discount-skeleton--container {
    background-color: #1e61c6;
    position: relative;
    display: flex;
    .discount-skeleton--wave {
        position: absolute;
        span {
            display: block;

            background-color: #eef2f9;
            border-radius: 50%;
        }
    }
    .discount-skeleton--content {
        width: 100%;
        > p {
            color: #ffffff;
            font-size: 10px;
            font-weight: 500;
            line-height: 10px;
            text-align: center;
            &:first-child {
                margin-bottom: 5px;
            }
        }

        .discount-skeleton--button {
            min-width: 102px;
            width: fit-content;
            max-width: 75%;
            background-color: #ffffff;
            padding: 2px 10px;
            margin: 15px auto 0;
            border-radius: 7px;

            p {
                text-align: center;
                font-size: 11.555px;
                font-weight: 500;
                line-height: 16px;
                white-space: pre-wrap;
                width: fit-content;
                margin: auto;
            }
        }
    }
}

.discount-type--column {
    width: fit-content;
    cursor: pointer;
    .before-discount-skeleton--container {
        width: 245px;
        height: fit-content;
        position: relative;
        .discount-percent {
            min-height: 100px;
            h1 {
                font-size: 38px;
                font-weight: 700;
                line-height: 100%;
                margin: 0;
            }
        }
        .discount-skeleton--content {
            height: fit-content;
            min-height: 100px;
            margin: 0px 10px;
            padding-bottom: 10px;
        }

        .discount-skeleton--divider {
            width: 100%;
            border: none;
            border-top: 3px dashed #ffffff;
            height: 0;
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &::before {
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
        }

        &::after {
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
        }
    }

    .after-discount-skeleton--container {
        width: 140px;
        min-height: 150px;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        .discount-skeleton--wave {
            height: 15px;
            width: 100%;
            span {
                width: 15px;
                height: 15px;
            }
            &.header {
                top: 0;
                transform: translateY(-50%);
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-evenly;
            }

            &.footer {
                bottom: 0;
                transform: translateY(50%);
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-evenly;
                span {
                    display: block;
                    width: 15px;
                    height: 15px;
                    background-color: #eef2f9;
                    border-radius: 50%;
                }
            }
        }
    }
    @media (max-width: 768px) {
        min-height: unset;
    }
}

.discount-type--row {
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    .before-discount-skeleton--container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: stretch;
        width: fit-content;
        min-width: 299px;
        height: fit-content;
        min-height: 90px;
        position: relative;
        .discount-percent {
            width: 103px;
            display: flex;
            align-items: center;
            justify-content: center;
            h1 {
                font-size: 16px;
                font-weight: 600;
                line-height: 100%;
            }
        }

        .discount-skeleton--divider {
            width: 0;
            border: none;
            border-left: 3px dashed #ffffff;
            margin: 0 3px;
        }

        .discount-skeleton--content {
            min-width: 190px;
            max-width: 250px;
            padding: 10px 10px 10px 5px;

            align-items: flex-start;
            & > p {
                margin: 0;
                text-align: left;
                white-space: pre-wrap;
                margin-bottom: 6px;
            }
        }
        &::before {
            top: 0;
            left: 105px;
            transform: translate(-25%, -50%);
        }

        &::after {
            bottom: 0;
            left: 105px;
            transform: translate(-25%, 50%);
        }
    }

    .after-discount-skeleton--container {
        width: 265px;
        min-height: 70px;
        padding: 9px 20px;
        justify-content: center;
        .discount-skeleton--wave {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
            top: 0;
            span {
                width: 10px;
                height: 10px;
            }
            &.header {
                left: 0;
                transform: translateX(-50%);
            }

            &.footer {
                right: 0;
                transform: translateX(50%);
            }
        }
        .discount-skeleton--content {
            .discount-skeleton--button {
                margin-top: 10px;
            }
        }
    }

    @media (max-width: 1439px) {
        min-height: unset;
    }

    @media (max-width: 1039px) {
        min-height: fit-content;
    }

    @media (max-width: 768px) {
        min-height: unset;
    }
}

.no-border {
    border: none !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.star {
    font-size: 24px;
    margin-right: 2px;
}

.filled {
    color: gold;
}

.outlined {
    color: lightgray;
}
.color-mode-option--field {
    width: 100%;
    max-width: 90px;
    cursor: pointer;
    .color-mode-option-skeleton--container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 7px 9px;
        border-radius: 6px;
        .color-mode-option-skeleton-item {
            border-radius: 2px;
            height: 8px;
            &.small {
                display: block;
                width: 8px;
            }
            &.medium-1 {
                display: block;
                width: 52px;
            }
            &.medium-2 {
                display: block;
                width: 40px;
            }
            &.large {
                display: block;
                width: 100%;
            }
        }
    }
}

.color-picker--detail {
    border: 1px solid var(--p-color-border);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 12px;
    h5 {
        &.Polaris-Text--root.Polaris-Text--semibold {
            margin: 7px 0 10px;
        }
    }
}
.setting-widget-body--container {
    height: calc(100% - 52px);
    &.hide {
        @media (max-width: 1039px) {
            height: 0;
        }
        @media (min-width: 1040px) {
            height: 100%;
        }
    }
}

.review-item--avt {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    p {
        font-weight: 700;
    }
}
